import React, { useEffect, useRef, useState } from "react"
import { Heading, Section } from "src/utils"
import { SanityCallToAction, SanityMedia } from "../../sanity/types"
// import { Container } from "../../layout/container/Container"
import cn from "classnames"
import s from "./CampaignModule.module.scss"
import { Container } from "../../layout/container/Container"
import CallToAction from "src/components/call-to-action/CallToAction"
import Media from "src/components/media/Media"
import { RollingText } from "src/components/rolling-text/RollingText"
// import RichText from "../../components/rich-text/RichText"
// import Angle from "../../components/angle/Angle"
// import CallToAction from "../../components/call-to-action/CallToAction"
// import Media from "src/components/media/Media"
import { useInView } from "react-intersection-observer"
import { generateSanityImage, getAspectRatioOMedia } from "src/sanity/image"
import { BasicImage } from "src/components/image/BasicImage"
// import ButtonGroup from "../../components/button-group/ButtonGroup"

interface Item {
    title: string
    media?: SanityMedia
    overlayMedia?: {
        media?: SanityMedia
        position?: string
    }
}

export interface CampaignModuleProps {
    title?: {
        text?: string
        size?: string
        lightFontWeight?: boolean
    }
    cta?: SanityCallToAction
    items: Item[]
}

export default function CampaignModule({
    title,
    cta,
    items,
}: CampaignModuleProps): JSX.Element {
    const [activeIndex, setActiveIndex] = useState(0)
    const scrollerRef = useRef()
    const [rollingTextContainerHeight, setRollingTextContainerHeight] = useState<number>()
    const [rollingTextScrollerOffset, setRollingTextScrollerOffset] = useState<number>()
    const isMobile = typeof window !== "undefined" ? window.innerWidth < 768 : true

    useEffect(() => {
        const ref: HTMLDivElement = scrollerRef?.current
        if (ref) {
            const children: HTMLSpanElement[] = Array.prototype.slice.call(ref.children).reverse()
            const height = children.slice(activeIndex, activeIndex + items.length - 1).reduce((acc, curr) => acc + curr.offsetHeight, 0)
            setRollingTextContainerHeight(height)
        }
    }, [scrollerRef, activeIndex])

    useEffect(() => {
        const ref: HTMLDivElement = scrollerRef?.current
        if (ref) {
            const children: HTMLSpanElement[] = Array.prototype.slice.call(ref.children)
            let offset
            if (isMobile)
                offset = children.slice(0, activeIndex).reduce((acc, curr) => acc + curr.offsetHeight, 0)
            else
                offset = children.slice(activeIndex, items.length).reduce((acc, curr) => acc + curr.offsetHeight, 0)
            setRollingTextScrollerOffset(-offset)

            if (activeIndex === items.length) {
                const resetOffset = isMobile ? 0 : children.slice(0, items.length).reduce((acc, curr) => acc + curr.offsetHeight, 0)
                setTimeout(() => {
                    ref.style.transition = "all 0s"
                    ref.style.transform = "translateY(" + -resetOffset + "px)"
                    ref.offsetHeight
                    ref.style.transition = null
                }, 610)
            }
        }
    }, [activeIndex])

    const { ref: inViewRef, inView } = useInView({
        threshold: 0,
        triggerOnce: false,
        rootMargin: "0px",
    })


    useEffect(() => {
        if (!inView) return

        let interval
        // First switch is after 1 second, then every 4 seconds
        const timeout = setTimeout(() => {
            setActiveIndex((lastActiveIndex) => lastActiveIndex >= items.length ? 1 : lastActiveIndex + 1)

            interval = setInterval(() => {
                setActiveIndex((lastActiveIndex) => lastActiveIndex >= items.length ? 1 : lastActiveIndex + 1)
            }, 4000)
        }, 1000)


        return () => {clearTimeout(timeout); clearInterval(interval)}
    }, [inView, items])


    return (
        <Section>
            <div className={cn(s["campaign-module__background"], "bg-primary-blue")}>
                <Container noMargin={true} className={cn(s["campaign-module"], { [s["campaign-module--in-view"]]: inView })} ref={inViewRef}>
                    <div className={cn(s["campaign-module__box-wrapper"])}>
                        <div className={cn(s["campaign-module__box"])}>
                            {/* Title */}
                            {title?.text && (
                                <Heading className={cn("hide-on-mobile", s["campaign-module__title"], title?.size, { "light-font-weight": title.lightFontWeight })}>{title.text}</Heading>
                            )}

                            {/* Item titles rolling */}
                            {/* Mobile */}
                            <div className={cn(s["campaign-module__rolling-text"], "hide-on-desktop")}>
                                <RollingText className={cn(s["campaign-module__rolling-active"], "heading-200", "light-font-weight")} rollingElements={items.map((item, i) => (<span key={i}>{item.title}</span>))} forcedActiveIndex={activeIndex % items.length} animation="roll-up"/>
                                <div className={cn(s["campaign-module__rolling-text__viewport"])} style={{ height: rollingTextContainerHeight + "px" }}>
                                    <div className={cn(s["campaign-module__rolling-text__scroller"])} style={{ transform: `translateY(${rollingTextScrollerOffset}px)` }} ref={isMobile ? scrollerRef : null}>
                                        {/* Create a list of of 2 times the items,
                                        then reverse (because the next active item should be in the bottom),
                                        then remove the first and last item (which is the active one, but will be displayed by the <RollingText> comp) */}
                                        {[...items.slice(1), ...items].map((item, i) => (<div key={item.title + i}>{item.title}</div>))}
                                    </div>
                                </div>
                            </div>
                            {/* Desktop */}
                            <div className={cn(s["campaign-module__rolling-text"], "hide-on-mobile")}>
                                <div className={cn(s["campaign-module__rolling-text__viewport"])} style={{ height: rollingTextContainerHeight + "px" }}>
                                    <div className={cn(s["campaign-module__rolling-text__scroller"])} style={{ transform: `translateY(${rollingTextScrollerOffset}px)` }} ref={isMobile ? null : scrollerRef} >
                                        {/* Create a list of of 2 times the items,
                                        then reverse (because the next active item should be in the bottom),
                                        then remove the first and last item (which is the active one, but will be displayed by the <RollingText> comp) */}
                                        {[...items.slice(1), ...items].reverse().map((item, i) => (<div key={item.title + i}>{item.title}</div>))}
                                    </div>
                                </div>
                                <RollingText className={cn(s["campaign-module__rolling-active"], "heading-200", "light-font-weight")} rollingElements={items.map((item, i) => (<span key={i}>{item.title}</span>))} forcedActiveIndex={activeIndex % items.length} animation="roll-down"/>
                            </div>

                            {/* CTA */}
                            {cta?.style  && (
                                <CallToAction {...cta} appearance="dark" className={cn(s["campaign-module__cta"])} />
                            )}
                        </div>
                    </div>

                    {/* Image box */}
                    <div className={cn(s["campaign-module__box-wrapper"])}>
                        <div className={cn(s["campaign-module__box"])}>

                            {/* Mobile Title */}
                            {title?.text && (
                                <Heading className={cn("hide-on-desktop", s["campaign-module__title"], title?.size, { "light-font-weight": title.lightFontWeight })}>{title.text}</Heading>
                            )}

                            {/* Images */}
                            <div className={cn(s["campaign-module__media-wrapper"])}>
                                {items.map((item, i) => (
                                    <div key={item.title} className={cn(s["campaign-module__media"], {
                                        "animate-in": activeIndex % items.length === i,
                                        "animate-out": activeIndex % items.length !== i,
                                    })}>
                                        {item.media?.type && (
                                            <Media
                                                media={item.media}
                                                sizes={[
                                                    "(min-width: 768px) 50vw",
                                                    "100vw",
                                                ]}
                                                step={500}
                                                width={item.media?.type === "image" ? 1800 : 900}
                                                height={item.media?.type === "image" ? 1800 : 900}
                                                // height={1800*0.8}
                                                // height={Math.ceil(1800/( (item?.media?.height && item?.media?.width) ? getAspectRatioOMedia(item.media) : 4/3 ) )}
                                                alt=""
                                                videoWithoutBorders
                                            />)}
                                        {item.overlayMedia?.media?.type === "image" && (
                                            <div className={cn(s["campaign-module__overlay-media"], s[`campaign-module__overlay-media--position-${item.overlayMedia.position}`])}>
                                                <BasicImage
                                                    sizes={[
                                                        "(min-width: 768px) 50vw",
                                                        "100vw",
                                                    ]}
                                                    aspectRatio={getAspectRatioOMedia(item.overlayMedia.media)}
                                                    {...generateSanityImage({
                                                        image: item.overlayMedia.media,
                                                        width: 1800,
                                                        // height: Math.ceil(1800/( (media?.media?.height && media?.media?.width) ? media.media.width/media.media.height : 16/9 ) ),
                                                        height: Math.ceil(1800/( (item.overlayMedia.media.height && item.overlayMedia.media.width) ? getAspectRatioOMedia(item.overlayMedia.media) : 4/3 ) ),
                                                        step: 500,
                                                    })}
                                                />
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </Section>
    )
}
