import React, { useRef } from "react"
import cn from "classnames"
import s from "./Angle.module.scss"

export default function Angle({
    flipped = false,
    color = "black",
    height: extraHeight = 1,
    className,
}: { className?: string, flipped?: boolean, color?: string, height?: number }) {
    const width = 100
    const height = Math.round(width * Math.tan(5 * Math.PI / 180))
    const ref = useRef<SVGSVGElement>()
    const buffer = extraHeight
    const path = flipped ? [[0, 0], [width, 0], [width, buffer], [0, height + buffer]] : [[0, height], [0, height + buffer], [width, height + buffer], [width, 0]]

    return (
        <div
            className={cn(s.angle, className)}
        >
            <svg
                ref={ref}
                className={cn(s.angle__svg)}
                viewBox={`0 0 ${width} ${height + buffer}`}
                style={{ margin: `${flipped ? -2 : 0}px -2px ${flipped ? 0 : -2}px` }}
            >
                <polygon
                    points={path.map(i => i[0] + " " + i[1]).join(", ")}
                    fill={color}
                />
            </svg>
        </div>
    )
}
