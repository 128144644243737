import React from "react"
import s from "./Module.module.scss"
import cn from "classnames"

interface ModuleProps extends React.HTMLProps<HTMLAnchorElement> {
    children: React.ReactNode
    moduleType?: string
    collapseMarginTop?: boolean
    collapseMarginBottom?: boolean
    removeMargin?: boolean
    id?: string
}

export function Module({ children, moduleType, collapseMarginTop, collapseMarginBottom, removeMargin, id }: ModuleProps): JSX.Element {
    return (
        <div data-module-name={moduleType} className={cn(s.module, {
            [s["module--collapse-margin-top"]]:collapseMarginTop || removeMargin,
            [s["module--collapse-margin-bottom"]]:collapseMarginBottom  || removeMargin })}
        id={id}
        >
            {children}
        </div>
    )
}
