import React from "react"
import cn from "classnames"
import s from "./Card.module.scss"
import { Heading, Only, Section } from "src/utils"
import { LinkItem, SanityImage, ThemeColor } from "src/sanity/types"
import Icon, { IconType } from "../icon/Icon"
import { BasicImage } from "../image/BasicImage"
import { generateSanityImage } from "src/sanity/image"
import { useSettings } from "src/layout/page/settingsContext"
import { LinkHandler } from "../link-handler/LinkHandler"

interface CardProps {
    className?: string
    title?: string
    link?: LinkItem
    badge?: string
    text?: string
    badgeTheme?: ThemeColor
    backgroundImage?: SanityImage
}

// joshy <3
// get string as number
function hashText(val: string) {
    let hash = 0
    let i: number
    let chr: number

    if (val.length === 0) return hash

    for (i = 0; i < val.length; i++) {
        chr = val.charCodeAt(i)
        hash = ((hash << 5) - hash) + chr
        hash |= 0 // Convert to 32bit integer
    }

    return Math.abs(hash)
}

function hasLinkAction(link: LinkItem): boolean {
    if (!link?.linkType) {
        return false
    }

    if (link.linkType === "event") {
        return !!link.event
    }

    return !!link.url
}

export default function Card({
    className,
    title,
    link,
    badge: tag,
    text,
    badgeTheme: tagTheme = "sky",
    backgroundImage,
}: CardProps) {
    const styles = {
        0: "nw",
        1: "ne",
        2: "sw",
        3: "se",
    }
    const { translations: t } = useSettings()

    return (
        <Section>
            <LinkHandler
                link={link}
                className={cn(s["card"], className, s["card--" + styles[hashText(title) % 4]], {
                    [s["card--link"]]: hasLinkAction(link),
                })}
            >
                <div className={s["card__content"]}>
                    <Heading className={cn(s["card__title"], "label-300")}>{title}<Icon type={IconType.ARROW_RIGHT} /></Heading>
                    <p className={cn("paragraph-200", s["card__text"])}>{text}</p>
                </div>

                <Only if={hasLinkAction(link)}>
                    <p className={cn(s["card__cta"], "label-200", "arrow-link--right")}>
                        {t.card.moreInfo}
                        <Icon type={IconType.ARROW_RIGHT} />
                    </p>
                </Only>

                <Only if={tag}>
                    <p className={cn(s["card__label"], "label-50", `bg-${tagTheme}`)}>{tag}</p>
                </Only>

                <Only if={backgroundImage}>
                    <div className={s["card__image-wrapper"]}>
                        <BasicImage
                            className={s["card__image"]}
                            sizes={["450px"]}
                            {...generateSanityImage({
                                image: backgroundImage,
                                width: 450,
                                height: 450,
                                step: 100,
                            })}
                            alt=""
                        />
                    </div>
                </Only>
            </LinkHandler>
        </Section>
    )
}
