import React from "react"
import cn from "classnames"
import s from "./ProductList.module.scss"

interface ProductListProps {
    className?: string
    children: React.ReactNode[]
    centered?: boolean
}

export default function ProductList({
    className,
    centered = false,
    children,
}: ProductListProps) {
    if (!children || !children.length) {
        return null
    }

    return (
        <ul
            className={cn(className, s["product-list"], {
                [s["product-list--centered"]]: centered && children.length < 4,
            })}
        >
            {React.Children.map(children, e => {
                return (
                    <li className={cn(s["product-list__item"])}>
                        {e}
                    </li>
                )
            })}
        </ul>
    )
}
