import React from "react"
import { getTextContrastColor, Section } from "src/utils"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import { ThemeColor } from "../../sanity/types"
import s from "./KeyNumbersModule.module.scss"

interface StatItem {
    id: string
    value: number
    suffix: string
    body: string

}

interface KeyNumbersModuleProps {
    items: StatItem[]
    backgroundColor: ThemeColor
    valueColor: ThemeColor
}


export default function KeyNumbersModule({ items, backgroundColor = "sky", valueColor }: KeyNumbersModuleProps): JSX.Element {
    return (
        <Section>
            <div className={cn(s["key-numbers-module"], `text-${valueColor}`, `bg-${backgroundColor}`)}>
                <Container>
                    <ul className={s["key-numbers-module__list"]}>
                        {items.map(item => {
                            return (
                                <li key={item.id} className={cn(s["key-numbers-module__item"])}>
                                    <Section>
                                        <h3 className={s["key-numbers-module__value"]}>
                                            <span className="heading-400">{item.value}</span> <span className="heading-200">{item.suffix}</span>
                                        </h3>
                                        <p className={`paragraph-200 ${getTextContrastColor(backgroundColor) || "text-black"}`}>{item.body}</p>
                                    </Section>
                                </li>
                            )
                        })}
                    </ul>
                </Container>
            </div>
        </Section>
    )
}
