import { useRouter } from "next/router"
import React from "react"
import { useBus } from "react-bus"
import s from "./ExampleOfInterest.module.scss"

import { numberWithDecimal } from "src/lib/numbers"

type ExampleOfInterestProps = {
    title: string
    exampleInterestRateTitle: string
    effectiveExampleInterestrate: string
    longestInterestRateTitle: string
    rewardButton: string
    rateOfAnnualInterestRebate?: number
    rateOfAnnualInterest: number
    effectiveRateForSelectedInstalment: number
    minLongestInterestRate: number
    discount: number
}

export const ExampleOfInterest = ({
    title,
    exampleInterestRateTitle,
    rateOfAnnualInterest,
    rateOfAnnualInterestRebate = 0,
    effectiveExampleInterestrate,
    effectiveRateForSelectedInstalment,
    longestInterestRateTitle,
    minLongestInterestRate = 2.95,
    // discount,
    rewardButton,
}: ExampleOfInterestProps) => {
    const bus = useBus()
    const { locale } = useRouter()
    const reward = Math.max((rateOfAnnualInterest - rateOfAnnualInterestRebate) - ((rateOfAnnualInterest - rateOfAnnualInterestRebate) / 6) , minLongestInterestRate)
    return (
        <section className={s["example-of-interest"]}>
            <p className={s["example-of-interest__title"]}>{title}</p>
            <ul>
                <li
                    className={s["example-of-interest__bullet"]}
                >
                    <span>{exampleInterestRateTitle}</span>
                    {!Number.isNaN(rateOfAnnualInterest - rateOfAnnualInterestRebate) && (<strong>{numberWithDecimal((rateOfAnnualInterest - rateOfAnnualInterestRebate), locale)}%</strong> )}
                </li>
                <li
                    className={s["example-of-interest__bullet"]}
                >
                    <span>{effectiveExampleInterestrate}</span>
                    { effectiveRateForSelectedInstalment && <strong>{numberWithDecimal(effectiveRateForSelectedInstalment, locale)}%</strong> }
                </li>
                <li
                    className={s["example-of-interest__bullet"]}
                >
                    <span>{longestInterestRateTitle}{" "}<span onClick={() => bus.emit("rewards")}>{rewardButton}</span></span>
                    { !Number.isNaN(reward) && <strong>{numberWithDecimal(reward, locale)}%</strong> }
                </li>
            </ul>
        </section>
    )
}


