import React from "react"
import s from "./Slider.module.scss"
import cn from "classnames"
interface SliderProps {
    value: number
    min: number
    max: number
    step: number
    onChange?: (newValue: number) => void
    className?: string
}

const setCSSProperty = (inputElement: HTMLInputElement | null) => {
    if(!inputElement) return
    const percent = ((Number(inputElement.value) - Number(inputElement.min)) / (Number(inputElement.max) - Number(inputElement.min))) * 100

    inputElement.style.setProperty("--webkitProgressPercent", `${percent}%`)
}


export const Slider = ({
    value,
    min,
    max,
    step,
    onChange,
    className,

}: SliderProps): JSX.Element => {
    const inputRef = React.useRef(null)

    const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement
        setCSSProperty(inputRef.current)
        onChange(Number(target.value))
    }
    
    React.useEffect(() => {
        setCSSProperty(inputRef.current)
    }, [inputRef])

    return (
        <input
            type="range"
            min={min}
            max={max}
            className={cn(s["slider"], className ? className : "")}
            value={value}
            onInput={(e) => handleChange(e)}
            onChange={(e) => handleChange(e)}
            ref={inputRef}
            step={step}
        />
    )
}
