import React from "react"
import { Section } from "src/utils"
import s from "./LeadParagraphModule.module.scss"
import { Container } from "src/layout/container/Container"
import { SanityCallToAction, SanityRichText, ThemeColor } from "src/sanity/types"
import RichText from "src/components/rich-text/RichText"
import CallToAction from "src/components/call-to-action/CallToAction"
import cn from "classnames"
interface LeadParagraphModuleProps {
    body: SanityRichText
    cta: SanityCallToAction
    talkieReversedColor: ThemeColor
    talkieColor: ThemeColor
    waffleColor: ThemeColor
    stickColor: ThemeColor
}

export default function LeadParagraphModule({ body, cta }: LeadParagraphModuleProps): JSX.Element {
    return (
        <Section>
            <Container>
                <div className={s["lead-paragraph-module"]}>
                    <RichText blocks={body}  className={cn("paragraph-400")} />
                    <CallToAction className={s["lead-paragraph-module__button"]} {...cta} />
                </div>
            </Container>
        </Section>
    )
}
