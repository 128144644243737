import { Heading, Only, Section } from "src/utils"
import React from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import RichText from "src/components/rich-text/RichText"
import { PageThemeType, SanityCallToAction, SanityMedia, SanityRichText, ThemeColor } from "../../sanity/types"
import CallToAction from "src/components/call-to-action/CallToAction"
import Icon, { IconType } from "src/components/icon/Icon"
import { LinkHandler } from "../../components/link-handler/LinkHandler"
import Media from "src/components/media/Media"
import s from "./TextGridModule.module.scss"
import { getAspectRatioOMedia } from "src/sanity/image"

export interface TextGridItem {
    id: string
    title?: string
    body?: SanityRichText
    media?: SanityMedia
    cta?: SanityCallToAction
    mediaStyle: "large" | "small" | "background"
    mediaOrientation: "horizontal" | "vertical" | "natural-height"
}

interface TextGridModuleProps {
    title: SanityRichText
    bodyText?: SanityRichText
    items: TextGridItem[]
    columns: number
    mobileColumns: number
    boxed?: boolean
    centerTextInItems?: boolean
    cta?: SanityCallToAction
    disclaimer?: SanityRichText
    backgroundColor?: ThemeColor
    pageThemeType?: PageThemeType
}

function WrapperLink({ cta, children, pageThemeType }: { cta: SanityCallToAction, children: React.ReactNode, pageThemeType?: PageThemeType }) {
    if (!cta?.style) {
        return (<>{children}</>)
    }

    return (
        <LinkHandler
            link={cta.link}
            className={cn(s["text-grid-module__item__link"], pageThemeType === "dark" ? s["text-grid-module__item__link--dark-theme"] : "")}
            prefetch={false}
        >
            {children}
        </LinkHandler>
    )
}

export default function TextGridModule({ title, bodyText, centerTextInItems, items, cta, disclaimer, backgroundColor, columns, mobileColumns, boxed, pageThemeType }: TextGridModuleProps): JSX.Element {
    const moduleContrastColor = ["primary-blue", "black", "paas-blue"].includes(backgroundColor)
    const hasImages = items?.filter(item => !!item.media?.type).length > 0
    return (
        <Section>
            <div className={cn({ [`bg-${backgroundColor}`]: backgroundColor, "has-background-color": !!backgroundColor }, moduleContrastColor ? "text-white" : null)}>
                <Container>
                    <div className={cn(s["text-grid-module"])}>
                        {title?.length && <Heading><RichText blocks={title} className={cn(s["text-grid-module__title"], "heading-200")} /></Heading>}
                        {bodyText && (
                            <RichText blocks={bodyText} className={cn(s["text-grid-module__body-text"])} />
                        )}

                        <div
                            className={cn(
                                s["text-grid-module__list"],
                                s["text-grid-module__list--columns-" + columns],
                                s["text-grid-module__list--mobile-columns-" + mobileColumns],
                                boxed ? s["text-grid-module__list--boxed"] : "",
                                hasImages ? s["text-grid-module__list--has-images"] : "",
                            )}
                        >
                            {(items || []).map((textGridItem, i) => {
                                const WrapperElement = boxed ? "div" : React.Fragment
                                const hasOnlyOneTextElement = [textGridItem?.title?.length > 0, textGridItem.body?.length, textGridItem.cta?.style].filter(Boolean).length < 2
                                return (

                                    <WrapperElement key={textGridItem.id} {...(WrapperElement === "div" ? { className: cn(s["text-grid-module__item__boxed-wrapper"], textGridItem.mediaStyle === "background" ? "text-white" : "") } : {})}>
                                        <div data-key={textGridItem.id} className={cn(
                                            s["text-grid-module__item"],
                                            s["text-grid-module__item-media"],
                                            textGridItem?.media && textGridItem.mediaStyle !== "background" && getAspectRatioOMedia(textGridItem.media) < 0.95 ? s["text-grid-module__item-media--clutch-to-bottom"] : "",
                                        )} style={{ gridArea: `item${i + 1}Media` }} >
                                            <WrapperLink cta={textGridItem.cta} pageThemeType={pageThemeType}>
                                                <Section>
                                                    {textGridItem.media?.type
                                                    && <div
                                                        className={cn(
                                                            s["text-grid-module__item__image"],
                                                            s["text-grid-module__item__image--" + textGridItem.mediaStyle],
                                                        )}
                                                    >
                                                        <Media
                                                            media={textGridItem.media}
                                                            sizes={["(max-width: 1400px) 50vw", `${(100 / columns).toFixed(0)}vw`]}
                                                            {...textGridItem.media}
                                                            width={textGridItem.media.width}
                                                            // height={textGridItem.media.height}
                                                            height={
                                                                // Image natural ratio if mediaStyle === "small" || mediaOrientation === "natural-height"
                                                                // Otherwise force image into 3:4 if vertical, 4:3 if horizontal
                                                                (textGridItem.mediaStyle === "small" || textGridItem.mediaStyle === "background" || textGridItem?.mediaOrientation === "natural-height") ? textGridItem.media.height
                                                                    : (textGridItem.mediaOrientation === "vertical" ? Math.round((4 / 3) * textGridItem.media.width)
                                                                        : Math.round((3 / 4) * textGridItem.media.width))

                                                            }
                                                            step={400}
                                                        />
                                                        {textGridItem.media.type === "video" && (
                                                            // Need this infront of the iFrame video to catch the click, apparently
                                                            <div className={cn(s["text-grid-module__item__click-catcher"])} />
                                                        )}
                                                    </div>
                                                    }
                                                </Section>
                                            </WrapperLink>
                                        </div>
                                        <div className={cn(
                                            s["text-grid-module__item"],
                                            s["text-grid-module__item-text"],
                                            centerTextInItems  ? "text-center" : "",
                                        )} style={{ gridArea: `item${i + 1}Text` }} >
                                            <WrapperLink cta={textGridItem.cta} pageThemeType={pageThemeType}>
                                                <Section>
                                                    {textGridItem.title && (
                                                        <Heading className={cn(s["text-grid-module__item__title"], "heading-50")}  >
                                                            {textGridItem.title}
                                                        </Heading>
                                                    )}

                                                    <RichText blocks={textGridItem.body} className="paragraph-100" />

                                                    <Only if={textGridItem?.cta?.style && textGridItem.cta.link.title.length > 1}>
                                                        <span className={cn(s["text-grid-module__item__cta"], hasOnlyOneTextElement ? "label-300" : "paragraph-100", "arrow-link--right")}>
                                                            <span>{textGridItem?.cta?.link.title?.split(" ").slice(0,-1).join(" ")}&nbsp;<span style={{ "whiteSpace": "nowrap" }}>{textGridItem?.cta?.link.title?.split(" ").slice(-1)?.[0]}<Icon type={IconType.ARROW_RIGHT} /></span></span>
                                                        </span>
                                                    </Only>
                                                </Section>

                                            </WrapperLink>
                                        </div>
                                    </WrapperElement>
                                )
                            })}
                        </div>

                        {cta?.style && (
                            <div className={s["text-grid-module__cta"]}>
                                <CallToAction style={cta?.style} link={cta?.link} />
                            </div>
                        )}
                    </div>
                    {disclaimer && (
                        <RichText blocks={disclaimer} className={s["text-grid-module__disclaimer"]} />
                    )}
                </Container>
            </div>
        </Section>
    )
}
