import { clamp, easeInOutCubic, Heading, Only, useAnimationFrame, useIsomorphicLayoutEffect } from "src/utils"
import React, { useEffect, useRef, useState } from "react"
import { SanityCallToAction, SanityImage } from "../../sanity/types"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import s from "./CardStylesModule.module.scss"
import { generateSanityImage } from "src/sanity/image"
import CallToAction from "src/components/call-to-action/CallToAction"
import Image from "src/components/image/Image"

interface Card {
    title: string
    image: SanityImage
    id: string
}

interface CardStylesModuleProps {
    title: string
    cta?: SanityCallToAction
    cards: Card[]
}

export default function CardStylesModule({ title, cta, cards }: CardStylesModuleProps): JSX.Element {
    const cardsRef = useRef<HTMLUListElement>()
    const targetProgress = useRef(0)
    const [innerHeight, setInnerHeight] = useState("auto")
    const [ready, setReady] = useState(false)

    useEffect(() => {
        const listener = () => {
            if (!cardsRef.current) {
                return
            }

            const { top, height } = cardsRef.current.getBoundingClientRect()
            const progress = 1 - clamp((-top + window.innerHeight / 2) / (height / 2), 0, 1)

            targetProgress.current = easeInOutCubic(progress)
            setReady(progress < .25)
        }

        listener()
        window.addEventListener("scroll", listener, { passive: true })

        return () => {
            window.removeEventListener("scroll", listener)
        }
    }, [])

    useIsomorphicLayoutEffect(() => {
        const { height } = cardsRef.current.getBoundingClientRect()

        setInnerHeight((height - 30) + "px")
    }, [])

    return (
        <div className={s["card-styles-module__wrapper"]}>
            <Only if={title}>
                <Container>
                    <Heading className={cn(s["card-styles-module__title"], "heading-300")}>
                        {title}
                    </Heading>
                </Container>
            </Only>
            <div
                className={s["card-styles-module__card-outer"]}
                style={{
                    "--inner-height": innerHeight,
                } as React.CSSProperties}
            >
                <ul
                    ref={cardsRef}
                    className={s["card-styles-module__cards"]}
                >
                    {cards.map((card, i) => {
                        return (
                            <Card
                                card={card}
                                index={i}
                                targetProgress={targetProgress}
                                key={card.id}
                                ready={ready}
                            />
                        )
                    })}
                </ul>
            </div>
            <Container>
                <CallToAction className={s["card-styles-module__button"]} {...cta} />
            </Container>
        </div>
    )
}

interface CardProps {
    card: Card
    index: number
    targetProgress: React.MutableRefObject<number>
    ready: boolean
}

function Card({ card, index, targetProgress, ready }: CardProps) {
    const ref = useRef<HTMLLIElement>()
    const currentProgress = useRef(0)

    useAnimationFrame(() => {
        if (!ref.current || window.matchMedia("(max-width: 850px)").matches) {
            return
        }

        const maxScale = 1
        const minScale = .8
        const maxTranslate = 100
        const minTranslate = 0
        const translate = minTranslate + (currentProgress.current * (maxTranslate - minTranslate))
        const scale = minScale + ((1 - currentProgress.current) * (maxScale - minScale))

        currentProgress.current += (targetProgress.current - currentProgress.current) * .65

        ref.current.style.transform = `
            scale(${(index !== 1 ? scale : 1).toFixed(3)})
            translate3d(${index === 0 ? `${(translate).toFixed(1)}%` : index === 2 ? `${-translate.toFixed(1)}%` : 0}, 0, 0)
        `
    }, [])


    return (
        <li
            ref={ref}
            key={card.id}
            className={s["card-styles-module__card"]}
        >
            <Image
                {...generateSanityImage({
                    image: card.image,
                    width: 268,
                    height: 424,
                    step: 50,
                    format: "png",
                })}
                alt={card.title}
                sizes={["268px"]}
            />
            <p
                className={cn(s["card-styles-module__card-title"], "heading-50")}
                style={{ opacity: ready || index === 1 ? 1 : 0 }}
            >
                {card.title}
            </p>
        </li>
    )
}
