import { Heading } from "src/utils"
import React from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import s from "./HeroBasicModule.module.scss"
import { ThemeColor, SanityCallToAction } from "src/sanity/types"
import type { DefaultHeroProps } from "../hero-base/types"
import CallToAction from "src/components/call-to-action/CallToAction"

export interface HeroBasicModuleProps extends DefaultHeroProps {
    titleColor: ThemeColor
    backgroundColor: ThemeColor
    cta?: SanityCallToAction
    subtitle?: string
}

export default function HeroBasicModule({ title, subtitle, backgroundColor = "night", titleColor = "white", cta }: HeroBasicModuleProps): JSX.Element {

    return (
        <div className={cn(s["hero-basic-module"])}>
            <div  className={cn(s["hero-basic-module"], `bg-${backgroundColor}`) }>
                <Container className={cn(s["hero-basic-module__inner"])}>
                    <Heading className={cn(s["hero-basic-module__title"], "heading-300", "light-font-weight", `text-${titleColor}`)}>{title}</Heading>
                    {subtitle && <span className={cn(s["hero-basic-module__subtitle"], "paragraph-200", `text-${titleColor}`)}>{subtitle}</span> }
                    {cta && (
                        <CallToAction className={ cn(s["hero-basic-module__cta-button"]) } {...cta} />
                    )}
                </Container>
            </div>
        </div>
    )
}

