import React from "react"
import s from "./Carousel.module.scss"
import cn from "classnames"
import { numberWithThousandSeparator } from "src/lib/numbers"

type CarouselItemProps = {
    periodInformation: string
    instalmentAmount: number
    currency: string
    numberOfYears: number
    selected?: boolean
    yearInfo: string
    onClick: () => void
}

export const CarouselItemSkeleton = () => <div className={s["carousel-item-skeleon"]}>{""}</div>

export const CarouselItem = ({
    periodInformation,
    instalmentAmount,
    currency,
    numberOfYears,
    selected,
    yearInfo,
    onClick,
}: CarouselItemProps) => {

    return (
        <div className={cn(s["carousel-item"], selected ? s["carousel-item--selected"] : "")} onClick={onClick}>
            <p className={s["carousel-item__period-information"]}>{periodInformation}</p>
            <div className={s["carousel-item__installment-info"]}>
                <h3>{numberWithThousandSeparator(instalmentAmount)}{" "}{currency.toUpperCase()}</h3>
                <p>{numberOfYears}{" "}{yearInfo}</p>
            </div>
        </div>
    )
}

