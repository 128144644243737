
import { formatDate } from "src/utils"
import React from "react"
import type { SanityRichText, ThemeColor } from "src/sanity/types"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import FullscreenMedia from "src/components/fullscreen-media/FullscreenMedia"
import s from "./HeroBlogModule.module.scss"
import Angle from "src/components/angle/Angle"
import RichText from "src/components/rich-text/RichText"
import type { DefaultHeroMediaProps, DefaultHeroProps } from "../hero-base/types"

interface HeroFullImageModuleProps extends DefaultHeroProps , DefaultHeroMediaProps {
    publishDate: string
    intro: SanityRichText
    mediaBackgroundColor: ThemeColor
}

export default function HeroBlogModule({
    publishDate,
    title,
    mediaBackgroundColor,
    intro,
    media,
}: HeroFullImageModuleProps): JSX.Element {
    return (
        <div
            className={cn(
                s["hero-blog-module"],
                `bg-${mediaBackgroundColor}`,
            )}
        >
            <div
                className={cn(
                    s["hero-blog-module__content"],
                )}
            >
                <Container>
                    <div
                        className={cn(
                            s["hero-blog-module__content__inner"],
                        )}
                    >
                        <time
                            dateTime={publishDate}
                            className={cn("label-50", s["hero-blog-module__date"])}
                        >
                            {formatDate(new Date(publishDate),  "en")}
                        </time>
                        <h1 className="heading-100">{title}</h1>
                        <div className={cn("paragraph-200", s["hero-blog-module__intro"])}  >
                            <RichText blocks={intro} />
                        </div>
                        <Angle className={s["hero-blog-module__content__angle"]} color="#FFF" />
                    </div>
                </Container>
            </div>

            {media && <FullscreenMedia media={media} />}
            <Angle className={s["hero-blog-module__angle"]} color="#FFF" />
        </div>
    )
}

