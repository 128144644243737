import React from "react"
import s from "./FeatureWalkthroughProgressBar.module.scss"
import cn from "classnames"
import { FALLBACK_DURATION } from "./FeatureWalkthroughModule"

interface FeatureWalkthroughProgressProps {
    duration: number
    activeTab: number
    index: number
    visible: boolean
    setActive: () => void
}

export default function FeatureWalkthroughProgressBar({
    duration = FALLBACK_DURATION,
    activeTab,
    setActive,
    visible,
    index,
}: FeatureWalkthroughProgressProps): JSX.Element {
    const isActive = activeTab === index

    return (
        <div
            className={cn(s["progress-bar__item"], isActive ? "active" : null)}
            onClick={() => setActive()}
        >
            <div
                style={{
                    animationDuration: `${duration}ms`,
                    animationPlayState: visible ? "running" :"paused",
                }}
                className={cn(s["progress-bar__item__inner"], {
                    [s["progress-bar__item__inner--active"]]: isActive,
                    [s["progress-bar__item__inner--done"]]: activeTab >= index,
                })}
            />
        </div>
    )
}
