import React from "react"
import { SanityMedia } from "src/sanity/types"
import s from "./FeatureWalkthroughItem.module.scss"
import cn from "classnames"
import Media from "src/components/media/Media"

interface FeatureWalkthroughItemProps {
    media: SanityMedia
    activeTab: number
    index: number
}

export default function FeatureWalkthroughItem({
    media,
    activeTab,
    index,
}: FeatureWalkthroughItemProps): JSX.Element {
    const isActive = activeTab === index

    return (
        <div
            className={cn(s["feature-walkthrough-item"], {
                [s["feature-walkthrough-item--is-active"]]: isActive,
            })}
        >
            <div className={cn(s["feature-walkthrough-item__media"])}>
                <Media
                    media={media}
                    {...media}
                    ignorePlaceholder
                    sizes={["292px"]}
                    width={292}
                    height={620}
                />
            </div>
        </div>
    )
}
