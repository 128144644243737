import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { SanityRichText } from "../../sanity/types"
import { LinkHandler } from "../link-handler/LinkHandler"

interface RichTextTitleProps {
    blocks: SanityRichText
    className?: string
    selectedColorField?: "colorTop" | "colorBottom"
}

const BlockRenderer = (props) => {
    const { style = "normal" } = props.node

    if (style === "normal") {
        return <>{props.children}</>
    }

    // Fall back to default handling
    return BlockContent.defaultSerializers.types.block(props)
}
const serializers = {
    types: {
        block: BlockRenderer,
    },
    marks: {
        block: BlockRenderer,
        link: ({ mark, children }) => {
            return <LinkHandler link={mark}>{children}</LinkHandler>
        },
        color: ({ mark, children }) => {
            const color = mark.colorBottom || mark.colorTop
            return <span className={"text-" + color}>{children}</span>
        },
    },
}

export default function RichTextTitle({ blocks }: RichTextTitleProps) {
    if (!blocks || blocks.length === 0) {
        return null
    }


    return (
        <BlockContent blocks={blocks} serializers={serializers} />
    )
}
