import { Container } from "src/layout/container/Container"
import s from "./TableModule.module.scss"
import cn from "classnames"
import React from "react"

interface TableRow {
    cells: string[]
    _key: string
}
interface TableModuleProps {
    rows: TableRow[]
}

export default function TableModule({ rows }: TableModuleProps) {
    // Highlight column, if header row starts with highlight:
    const highlightedColumnIndexes = rows[0].cells.reduce((acc, currentCell, currentCellIndex) => {
        if (currentCell.includes("highlight:"))
            acc.push(currentCellIndex)
        return acc
    }, [])

    const highlightedRowIndexes = rows.reduce((acc, currentRow, currentRowIndex) => {
        if (currentRow.cells[0].includes("highlight:"))
            acc.push(currentRowIndex)
        return acc
    }, [])
    // bold column, if header row starts with bold:
    const boldedColumnIndexes = rows[0].cells.reduce((acc, currentCell, currentCellIndex) => {
        if (currentCell.includes("bold:"))
            acc.push(currentCellIndex)
        return acc
    }, [])

    const boldedRowIndexes = rows.reduce((acc, currentRow, currentRowIndex) => {
        if (currentRow.cells[0].includes("bold:"))
            acc.push(currentRowIndex)
        return acc
    }, [])

    return (
        <Container className={s["table__container"]}>
            <table className={s["table"]}>
                <thead>{renderRow(rows[0], [], false, [], false, "th")}</thead>
                <tbody>
                    {rows.slice(1).map((row, rowIndex) => renderRow(row, highlightedColumnIndexes, highlightedRowIndexes.includes(rowIndex + 1), boldedColumnIndexes, boldedRowIndexes.includes(rowIndex+1)))}
                </tbody>
            </table>
        </Container>
    )
}

function renderRow(row: TableRow, highlightedColumnIndexes = [], highlight = false, boldedColumnIndexes = [], bold = false, tableCellTag = "td") {
    // tr or th
    const TableCellElement = tableCellTag as keyof JSX.IntrinsicElements
    return (
        <tr key={row._key}>
            {row.cells.map((cell, i) => (
                <TableCellElement key={cell + i} className={cn(
                    (cell.includes("highlight:") || highlight || highlightedColumnIndexes.includes(i)) ? s["table__cell--highlighted"] : null,
                    (cell.includes("bold:") || bold || boldedColumnIndexes.includes(i)) ? s["table__cell--bold"] : null,
                )}>{cell.replaceAll("highlight:", "").replaceAll("bold:", "")}</TableCellElement>
            ))}
        </tr>
    )
}
