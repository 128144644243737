import { clamp, Heading, Section, useAnimationFrame } from "src/utils"
import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./StatementsCircleModule.module.scss"
import { ThemeColor } from "../../sanity/types"
import { colorValueMap } from "src/sanity/colors"


interface Statement {
    id: string
    color: ThemeColor
    title: string

}

interface StatementsCircleModuleProps {
    statements: Statement[]
    circleColor: ThemeColor
    title: string
    titleColor: ThemeColor
}

export default function StatementsCircleModule({ titleColor, title, circleColor, statements }: StatementsCircleModuleProps): JSX.Element {
    const canvasRef = useRef<HTMLCanvasElement>()
    const outerRef = useRef<HTMLDivElement>()
    const targetRadius = useRef(0)
    const currentRadius = useRef(0)
    const [canvasSize, setCanvasSize] = useState<number[]>([0, 0])
    const [index, setIndex] = useState<number>(0)
    const [visible, setVisible] = useState(false)
    const size = 75 // in vh units
    const [height, setHeight] = useState(`${size}vh`)
    const [screenHeight, setScreenHeight] = useState(`100vh`)
    const extraBuffer = 75

    useEffect(() => {
        // can't rely on browsers own vh as it changes as the user scrolls on smaller
        // screens, this is a inner size replacement
        setHeight(window.innerHeight * (size / 100) + "px")
        setScreenHeight(window.innerHeight + extraBuffer + "px")
    }, [])

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting)
        }, { threshold: [0] })

        observer.observe(outerRef.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        const onResize = () => {
            // dont throttle for now, eases awkward resize of vh unit when
            // browser chrome pops in/out
            setScreenHeight(window.innerHeight + extraBuffer + "px")
            setCanvasSize(() => {
                const devicePixelRatio = Math.min(window.devicePixelRatio, 2)

                return [window.innerWidth * devicePixelRatio, (window.innerHeight + extraBuffer) * devicePixelRatio]
            })
        }

        window.addEventListener("resize", onResize)
        setCanvasSize([window.innerWidth, window.innerHeight + extraBuffer])

        return () => {
            window.removeEventListener("resize", onResize)
        }
    }, [])

    useEffect(() => {
        const onScroll = () => {
            if (!outerRef.current) {
                return
            }

            const { top, height } = outerRef.current.getBoundingClientRect()
            const innerHeight = window.innerHeight
            const circleProgress = clamp(-top / ((height - innerHeight) * .2), 0, 1)
            const index = clamp((-top - ((height - innerHeight) * .185)) / ((height - innerHeight) * .6), -1, 1) * statements.length

            targetRadius.current = circleProgress * Math.min(window.devicePixelRatio, 2)
            setIndex(Math.min(Math.floor(index), statements.length - 1))
        }

        onScroll()

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [statements?.length])

    useAnimationFrame(() => {
        if (!visible || !canvasRef.current) {
            return
        }

        const context = canvasRef.current.getContext("2d")
        const [width, height] = canvasSize
        const radius = Math.sqrt(width ** 2 + height ** 2) / 2 * 1.01

        currentRadius.current += (targetRadius.current - currentRadius.current) * .9

        context.clearRect(0, 0, width, height)
        context.fillStyle = colorValueMap[circleColor]
        context.beginPath()
        context.arc(width / 2, height / 2, radius * currentRadius.current, 0, Math.PI * 2)
        context.fill()
    }, [canvasSize, visible])

    return (
        <Section>
            <div
                className={s["statements-circle-module"]}
                ref={outerRef}
                style={{
                    "--height": `calc(${height} * ${statements?.length})`,
                    "--screen-height": screenHeight,
                } as React.CSSProperties}
            >
                <div className={s["statements-circle-module__inner"]} style={{ transform: `translateY(-${extraBuffer / 2}px)` }}>
                    <Heading className={cn(`text-${titleColor}`, s["statements-circle-module__inner__title"], "heading-400")}>
                        {title}
                    </Heading>

                    <canvas
                        width={canvasSize[0]}
                        height={canvasSize[1]}
                        ref={canvasRef}
                        className={cn(s["statements-circle-module__inner__canvas"])}
                    />

                    <ul className={cn(s["statements-circle-module__inner__statements"])}>
                        {statements.map((i, ii) => (
                            <li className={cn("heading-400", `text-${i.color}`)} key={i.id} style={{ display: ii === index ? "block" : "none" }}>
                                {i.title}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Section>
    )
}
