import { clamp, useAnimationFrame } from "src/utils"
import React, { useEffect, useRef, useState } from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import s from "./StatementsModule.module.scss"
import Angle from "src/components/angle/Angle"
import { HorizontalPosition, SanityMedia, ThemeColor, VerticalPosition } from "src/sanity/types"
import Media from "src/components/media/Media"

interface StatementsModuleProps {
    media: SanityMedia
    textPosition: HorizontalPosition
    shapeColor: ThemeColor
    shapePosition: VerticalPosition
    statements: string[]
}

export default function StatementsModule({
    statements = [],
    media,
    shapeColor,
    textPosition,
    shapePosition,
}: StatementsModuleProps) {
    const ref = useRef<HTMLDivElement>()
    const titleRefTop = useRef<HTMLDivElement>()
    const titleRefBottom = useRef<HTMLDivElement>()
    const shapeRef = useRef<HTMLDivElement>()
    const targetOpacity = useRef<number>(0)
    const currentOpacity = useRef<number>(0)
    const scrollY = useRef<number>(0)
    const currentY = useRef<number>(0)
    const size = 35 // in vh units
    const [height, setHeight] = useState(`${size}vh`)
    const [index, setIndex] = useState(0)
    const [visible, setVisible] = useState(false)
    const [top, setTop] = useState(0)
    const flipped = textPosition === "right"

    useEffect(() => {
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting)
        }, { threshold: [0] })

        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        setHeight(window.innerHeight * (size / 100) + "px")
    }, [])

    useAnimationFrame(() => {
        const isSmall = window.matchMedia("(max-width: 900px)").matches
        const titleRef = (isSmall ? titleRefBottom : titleRefTop).current

        if (!visible || !shapeRef.current || !titleRef) {
            return
        }


        currentOpacity.current += (targetOpacity.current - (currentOpacity.current)) * .5
        currentY.current += (scrollY.current - (currentY.current)) * .5

        titleRef.style.opacity = currentOpacity.current.toFixed(3) // strange .001 leftover??
        shapeRef.current.style.transform = `translate3d(0, ${(currentY.current * 60).toFixed(2)}%, 0)`
    }, [visible])

    useEffect(() => {
        const onScroll = () => {
            if (!ref.current) {
                return
            }

            const isSmall = window.matchMedia("(max-width: 900px)").matches // css break poinr layout swp for
            const { top, bottom, height } = ref.current.getBoundingClientRect()
            const innerHeight = window.innerHeight
            const buffer = 40
            const penalty = isSmall ? 0 : innerHeight / 2
            const topProgress = isSmall ? 1 : clamp((-top + penalty) / buffer, 0, 1)
            const bottomProgress = 1 - clamp((bottom - (isSmall ? innerHeight * .2 : innerHeight / 2)) / buffer, 0, 1)
            const index = Math.floor(clamp(
                (-top + (isSmall ? 0 : innerHeight / 2)) / (height - (isSmall ? innerHeight / 2 : 0)),
                0,
                1,
            ) * (statements.length))

            setIndex(Math.min(index, statements.length - 1))

            targetOpacity.current = topProgress - bottomProgress
            scrollY.current = (-top) / height / 2
        }

        onScroll()

        window.addEventListener("scroll", onScroll)

        return () => {
            window.removeEventListener("scroll", onScroll)
        }
    }, [statements?.length])

    useEffect(() => {
        if (!titleRefBottom.current || !titleRefTop.current) {
            return
        }

        const isSmall = window.matchMedia("(max-width: 900px)").matches
        const { height } = (isSmall ? titleRefBottom : titleRefTop).current.getBoundingClientRect()

        // calc manual top to center text in screen
        setTop((((window.innerHeight - height) / 2) / window.innerHeight) * 100)
    }, [statements?.length])

    return (
        <div
            className={s["statements-module"]}
            ref={ref}
            style={{
                "--height": `calc(${height} * ${statements?.length})`,
            } as React.CSSProperties}
        >
            <div className={cn(s["statements-module__title-wrapper"], s["statements-module__title-wrapper--top"])}>
                <div
                    style={{
                        top: `${top}%`,
                    }}
                    className={cn(s["statements-module__title"], {
                        [s["statements-module__title--right"]]: flipped,
                        [s["statements-module__title--left"]]: !flipped,
                    })}
                >
                    <Container>
                        <div
                            ref={titleRefTop}
                            className={cn(s["statements-module__title__inner"])}
                        >
                            {(statements || []).map((text, i) => {
                                let color = "rgba(0, 0, 0, .15)"

                                if (i === index) {
                                    color = "var(--marine)"
                                } else if (index > i) {
                                    color = "black"
                                }

                                return (
                                    <p
                                        style={{
                                            color,
                                            textTransform: "uppercase",
                                        }}
                                        className={cn("heading-300", s["statements-module__title__inner__element"], {
                                            [s["statements-module__title__inner__element--right"]]: flipped,
                                            [s["statements-module__title__inner__element--left"]]: !flipped,
                                        })}
                                        key={i}
                                    >
                                        {text}
                                    </p>
                                )
                            })}
                        </div>
                    </Container>
                </div>
            </div>

            <div className={s["statements-module__background"]}>
                <Container
                    className={cn(s["statements-module__background__inner"], {
                        [s["statements-module__background__inner--left"]]: !flipped,
                        [s["statements-module__background__inner--right"]]: flipped,
                    })}
                >
                    <div
                        className={cn(s["statements-module__background__shape"], `text-${shapeColor}`, {
                            [s["statements-module__background__shape--left"]]: !flipped,
                            [s["statements-module__background__shape--right"]]: flipped,
                        })}
                        ref={shapeRef}
                    >
                        <Angle
                            flipped={shapePosition === "bottom"}
                            height={100}
                            className={cn(
                                s["statements-module__background__shape__angle"],
                                s["statements-module__background__shape__angle--" + shapePosition],
                            )}
                            color="currentColor"
                        />
                    </div>
                    <div
                        className={cn(s["statements-module__background__image"], {
                            [s["statements-module__background__image--right"]]: !flipped,
                            [s["statements-module__background__image--left"]]: flipped,
                        })}
                    >
                        <Media
                            media={media}
                            {...media}
                            sizes={["440px"]}
                            width={440}
                            height={586}
                            alt=""
                        />
                    </div>
                </Container>
            </div>

            <div className={cn(s["statements-module__title-wrapper"], s["statements-module__title-wrapper--bottom"])} aria-hidden>
                <div
                    style={{
                        top: `${top}%`,
                    }}
                    className={cn(s["statements-module__title"], {
                        [s["statements-module__title--right"]]: flipped,
                        [s["statements-module__title--left"]]: !flipped,
                    })}
                >
                    <Container>
                        <div
                            ref={titleRefBottom}
                            className={cn(s["statements-module__title__inner"])}
                        >
                            {(statements || []).map((text, textIndex) => {
                                let color = "rgba(0, 0, 0, .15)"

                                if (textIndex === index) {
                                    color = "var(--marine)"
                                } else if (index > textIndex) {
                                    color = "black"
                                }

                                return (
                                    <p
                                        style={{
                                            color,
                                            textTransform: "uppercase",
                                        }}
                                        className={cn("heading-300", s["statements-module__title__inner__element"], {
                                            [s["statements-module__title__inner__element--right"]]: flipped,
                                            [s["statements-module__title__inner__element--left"]]: !flipped,
                                        })}
                                        key={textIndex}
                                    >
                                        {text}
                                    </p>
                                )
                            })}
                        </div>
                    </Container>
                </div>
            </div>
        </div>
    )
}
