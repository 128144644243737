import React from "react"
import { Section } from "src/utils"
import { SanityMedia, ThemeColor } from "../../sanity/types"
import { Container } from "src/layout/container/Container"
import Media from "src/components/media/Media"
import cn from "classnames"
import s from "./ImageModule.module.scss"
import { getAspectRatioOMedia } from "src/sanity/image"

interface ImageModuleProps {
    maxWidth: string
    media?: SanityMedia
    backgroundColor?: ThemeColor
    mobileMedia?: SanityMedia
}

export default function ImageModule({ maxWidth, media, mobileMedia, backgroundColor }: ImageModuleProps): JSX.Element {
    const columns = maxWidth === "edge-to-edge" ? 12 : parseInt(maxWidth)

    return (
        <Section>
            <div className={cn(s["image-module__background"], { [`bg-${backgroundColor}`]: backgroundColor })}>
                <Container className={cn(maxWidth === "edge-to-edge" ? s["image-module__container--edge-to-edge"] : null)} noMargin={maxWidth === "edge-to-edge"}>
                    {media?.type && (
                        <div className={cn(s["image-module"], s[`image-module--maxwidth-${columns}`], mobileMedia?.type ? "hide-on-mobile" : null)}>
                            <Media
                                media={media}
                                sizes={["(max-width: 768px) 100vw", ((columns / 12) * 100) + "vw"]} // x2 for retina
                                // sizes={["(max-width: 768px) 768", "(max-width: 1024px) 1024", "1200px"]}
                                step={400}
                                width={1920}
                                height={1920 / getAspectRatioOMedia(media)}
                                alt=""
                            />
                        </div>
                    )}
                    {mobileMedia?.type && (
                        <div className={cn(s["image-module"], s[`image-module--maxwidth-${columns}`], "hide-on-desktop")}>
                            <Media
                                media={mobileMedia}
                                sizes={["(max-width: 768px) 100vw", ((columns / 12) * 100) + "vw"]} // x2 for retina
                                // sizes={["(max-width: 768px) 768", "(max-width: 1024px) 1024", "1200px"]}
                                step={400}
                                width={800}
                                height={800 / getAspectRatioOMedia(mobileMedia)}
                                alt=""
                            />
                        </div>
                    )}
                </Container>
            </div>
        </Section>
    )
}



