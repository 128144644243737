import React, { useEffect, useRef, useState } from "react"
import cn from "classnames"
import s from "./InfoModal.module.scss"
import Router from "next/router"
import { useListener } from "react-bus"
import { Link } from "src/components/link/Link"
import { createFocusTrap } from "focus-trap"
import RichText from "../rich-text/RichText"
import { useSettings } from "src/layout/page/settingsContext"
import Icon, { IconType } from "../icon/Icon"
import { Container } from "src/layout/container/Container"
import buildLink from "src/sanity/buildLink"
import { InternalLink, SanityRichText } from "src/sanity/types"

interface InfoModalProps {
    event: string
    title?: string
    body: SanityRichText
    modalVersion?: boolean
}

export default function InfoModal({ event, body, modalVersion, title }: InfoModalProps)  {
    const [open, setOpen] = useState(false)
    const { translations: t, segment, lang } = useSettings()
    Router.events.on("routeChangeStart", () =>  setOpen(false))
    Router.events.on("routeChangeComplete", () =>  setOpen(false))
    Router.events.on("routeChangeError", () =>  setOpen(false))

    const homeLink: InternalLink = {
        id: "",
        title: "",
        linkType: "internal",
        type: "link",
        url: {
            type: "frontpage",
            lang,
            segment,
            slug: "",
        },
    }

    const ref = useRef<HTMLDivElement>()

    useListener(event, () => {
        setOpen(true)
    })

    useEffect(() => {
        if (open) {
            document.body.style.overflow = "hidden"
        } else {
            document.body.style.overflow = "auto"
        }
    }, [open])

    useEffect(() => {
        if (open) {
            const trap = createFocusTrap(ref.current, {
                onDeactivate: () => setOpen(false),
            })

            trap.activate()

            return () => {
                trap.deactivate()
            }
        }
    }, [open])

    return (
        <div className={s["info-modal"]}>
            <div                 ref={ref}
                style={{ opacity: open ? "1" : "0", pointerEvents: open ? "all" : "none" }}
                className={cn(s["info-modal__popup"], "blur", { [s["info-modal__popup--closed"]]:!open })}>

                <Container className={modalVersion ? s["info-modal__modal-content"] : ""}>
                    <Link href={buildLink(homeLink)} className={cn(s["info-modal__logo"])}>
                        <Icon className={s["info-modal__logo__svg"]} type={IconType.LOGO} />
                        <span className="visually-hidden">
                            Lunar {t?.header?.home.toLocaleLowerCase()}
                        </span>
                    </Link>
                    <button
                        onClick={() => setOpen(false)}
                        className={cn(s["info-modal__close"], "button", "button--close")}
                    >
                        <Icon type={IconType.X} />
                        <span className="visually-hidden">{t?.languages?.close}</span>
                    </button>

                    <div className={ s["info-modal__modal-content-wrapper"]}>
                        { title && <h3 className={cn(s["info-modal__title"], "heading-200")}>{title}</h3> }
                        <RichText blocks={body} className={s["paragraph-200"]} />
                    </div>
                </Container>
            </div>
        </div>
    )
}
