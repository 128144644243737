
import fetchy from "src/api/fetch"
import { CategorizedCreditClass } from "./credit-class"

export async function getRates() {
    return await fetchy<Rates>("/api/lendify/get-rates")
}

export interface Rates {
    rates: CategorizedCreditClass<{
        category: string
        min: number
        max: number
    }>
    serviceFee: CategorizedCreditClass<{
        category: string
        min: number
        max: number
    }>
    originationFee: CategorizedCreditClass<{
        category: string
        avgRate: number
    }>
}

export async function getLoanRates() {
    return await fetchy<LoanRates>("/api/lendify/get-loan-rate")
}

export interface LoanRates {
    originationFee: number
    rates: {
        [key: `year${number}`]: {
            rate: number
            discount: number
        }
    }
}
