import { PreviewData } from "next"
import { IconType } from "src/components/icon/IconDefinitions"
import { TemplateType } from "src/templates"

export interface SanityImage {
    type: "image"
    src: string
    placeholder: string
    alt: string
    width: number
    height: number
    hotspot?: Sanity.ImageHotspot
    crop?: Sanity.ImageCrop
    originalFilename?: string
}

export interface SanityVideo {
    type: "video"
    autoplay: boolean
    controls: boolean
    duration: number
    height: number
    loop: boolean
    muted: boolean
    src: string
    width: number
    thumbnail: {
        url: string
        height: number
        width: number
    }
}

interface VideoFile {
    link: string
    quality: string
    size: number
    width: number
    height: number
}
export interface SanityVimeoFiles extends Omit<SanityVideo, "type" | "thumbnail" | "src"> {
    type: "vimeoFiles"
    vimeoUrl: string
    videoFiles: VideoFile[]
    thumbnail: string
}
export interface SanityIcon {
    type: "icon"
    icon: IconType
    width: null
    height: null
}

export type SanityMedia = SanityImage | SanityVideo | SanityVimeoFiles | SanityIcon
export type SanityRichText = Sanity.Schema.RichText

export interface SanityCallToAction {
    link: LinkItem
    style: "link" | "button"
    id?: string
}

// call to action that does not include any title or style
export type SanityCallToActionAnonymous = Omit<SanityCallToAction, "style">

export type ThemeColor = Sanity.Schema.Colors
export type VerticalPosition = "top" | "bottom"
export type HorizontalPosition = "left" | "right"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type Module = any

export enum ModuleType {
    CURRENCY_CONVERTER = "currencyConverterModule",
    CURRENCY_TABLE = "currencyTableModule",
    PRODUCT_LIST = "productListModule",
    HERO_BLOG = "heroBlogModule",
    SUBSCRIPTIONS_LIST = "subscriptionsListModule",
    HEADLINE_TEXT_AND_CTAA = "headlineTextAndCtaModule",
    CAMPAIGN = "campaignModule",
    COLLAGE_SCREENSHOT_LARGE = "collageScreenshotLargeModule",
    COLLAGE_SCREENSHOT = "collageScreenshotModule",
    COLLAGE = "collageModule",
    COLLAGE_DEFAULT = "collageDefaultModule",
    CUSTOM_COMPONENT = "customComponentModule",
    FULLSCREEN_IMAGE = "fullscreenImageModule",
    HERO_COLLAGE = "heroCollageModule",
    HERO_FULL_IMAGE = "heroFullImageModule",
    SUBSCRIPTION_TABLE = "subscriptionTableModule",
    HERO_SUBSCRIPTIONS = "heroSubscriptionsModule",
    HERO_BASIC = "heroBasicModule",
    HERO_PARALLAX = "heroFancyModule",
    USP = "uspModule",
    LEAD_PARAGRAPH = "leadParagraphModule",
    RICH_TEXT = "richTextModule",
    RIBBON = "ribbonModule",
    TESTIMONIALS = "testimonialsModule",
    KEY_NUMBERS = "keyNumbersModule",
    TEXT_GRID = "textGridModule",
    BLOG_POST_GRID = "blogPostGridModule",
    QA_LIST = "qaListModule",
    STEPS = "stepsModule",
    FEATURES_GROUP = "featuresGroupModule",
    STATEMENTS = "statementsModule",
    PRODUCT_OVERVIEW = "productOverviewModule",
    STATEMENTS_CIRCLE = "statementsCircleModule",
    CARD_STYLES = "cardStylesModule",
    VIDEO = "videoModule",
    FEATURE_WALKTHROUGH = "featureWalkthroughModule",
    IMAGE = "imageModule",
    INVESTMENT = "investmentModule",
    LOAN = "loanModule",
    WILLPOWERQUOTE = "willPowerQuoteModule",
    TYPEFORM = "typeFormModule",
    RATE_TABLE = "rateTableModule",
    TABLE = "tableModule"
}

export interface BaseModule extends Module {
    type: ModuleType
    id: string
}

export interface Slug {
    current: string
}

export interface BaseLink {
    id: string
    title: string
    type: "link"
    gtmEvent?: string
    withArrow?: boolean
}

export interface ExternalLink extends BaseLink {
    linkType: "external"
    url: string
}

export interface InternalLink extends BaseLink {
    linkType: "internal"
    url: {
        type?: string
        segment?: string
        lang: string
        slug: string
    }
}

export interface EventLink extends BaseLink {
    linkType: "event"
    event: Sanity.Schema.EventLink
}

export type LinkItem = InternalLink | ExternalLink | EventLink

export interface GlobalSettingsLinkGroup {
    type: "linkGroup"
    id: string
    label: string
    groupWithPrevious: boolean
    children: (GlobalSettingsLinkGroup | LinkItem)[]
    cta?: SanityCallToAction
}

export interface GlobalSettings {
    id: string
    headerCTALabel: string
    description: string
    title: string
    type: string
    seo: SEOData
    navigation?: {
        header?: (GlobalSettingsLinkGroup | LinkItem)[]
        footer?: (GlobalSettingsLinkGroup | LinkItem)[]
    }
    lang: Sanity.Schema.GlobalSettings["lang"]
    segment: Sanity.Schema.Segment
    settings: {
        title: string
        lang: Sanity.Schema.GlobalSettings["lang"]
        segment: Sanity.Schema.Segment
        link: InternalLink
    }[]
    legalText: SanityRichText
    soMeNav: { title: string, url: string }[]
    linkAppStore: string
    linkGooglePlay: string
    faq: {
        link?: InternalLink
        backLink: string
    }
    notfoundPage?: InternalLink
    errorPage?: InternalLink
    downloadDetails: DownLoadDetails
    downloadOverlay: DownLoadDetails
    newsRibbon?: Module
    showOnlyNewsRibbonOnFrontpage?: boolean
    loginDetails: LoginDetails
    translations: Sanity.Schema.GlobalSettings["translations"]
}

export type Locale = "dk" | "en" | "se" | "no"
export type Segment = "private" | "business"
export interface LocaleSegments {
    dk: {
        private: "privat"
        business: "erhverv"
    }
    en: {
        private: "private"
        business: "business"
    }
    se: {
        private: "privat"
        business: "foretag"
    }
    no: {
        private: "privat"
        business: "bedrift"
    }
}
export interface BasePage<T> {
    initialData: T
    preview?: boolean
    previewData?: {"token": string} & PreviewData
    globalSettings: GlobalSettings
    docType: TemplateType
    slug: string[]
    resolvedUrl: string
    locale?: string
    query: string
    queryParams: {
        slug?: string
        locale: string
        segment?: string
    }
}

export type SEOType = "website" | "article"

export type PageThemeType = Sanity.Schema.Page["pageThemeType"]

export type HeaderStyle = "light" | "dark" | "inverted"

export type HeroStyle = "white" | "black" | "inverted"

export interface ShapeController {
    topLeftRadius: "round" | "edge"
    topRightRadius: "round" | "edge"
    bottomRightRadius: "round" | "edge"
    bottomLeftRadius: "round" | "edge"
}

export interface SEOData {
    pageTitle?: string
    title?: string
    description?: string
    type?: SEOType
    url?: string
    imageUrl?: string
    noIndex?: boolean
}

export interface FooterDetails {
    legalText?: SanityRichText
    linkGooglePlay: string
    linkAppStore: string
    downloadDetails: DownLoadDetails
}

export interface DownLoadDetails {
    title: string
    subtitle: string
    text: string
    successMessage: string
    errorMessage: string
    ctaText: string
    steps: string[]
    onelink?: string
    contactTitle?: string
    contactSubtitle?: string
    contactCtaText?: string
    showInboundPhoneNumber?: boolean
    _id?: string
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DownloadOverlay extends DownLoadDetails {}
export interface LoginDetails {
    accountTitle: string
    appAccountTitle: string
    appAccountCTA: string
    loginTitle: string
    loginCTA: string
    loginButton: string
    loginSubtitle: string
    mobileID: string
    bankIDOnFile: string
    getBankID: string
    loginMedia: SanityImage
}

export interface Product {
    page: InternalLink
    title: string
    id: string
    shortDescription: string
    badge: string
    badgeTheme: ThemeColor
    backgroundImage?: SanityImage
}

export interface ProductCategory {
    id: string
    title: string
    products: Product[]
}

export interface PageReference {
    lang: Locale
    page: InternalLink
}


export interface Benefit {
    id: string
    labelId: string
    style: "text" | "checkmark"
    value?: string
}

export interface Subscription {
    id: string
    title: string
    price: string
    priceYearly?: string
    description?: SanityRichText
    page: InternalLink
    getAppLink: SanityCallToAction
    cardImage: SanityImage
    cardImageAlternatives: { image: SanityImage }[]
    benefits: Benefit[]
}

export interface BenefitType {
    id: string
    title: string
    tooltipText?: SanityRichText
    badgeText?: string
}

export interface BenefitGroup {
    id: string
    title: string
    benefitTypes: BenefitType[]
}

export type ExchangeRateCode = string
export type ExchangeRateMap = Record<ExchangeRateCode, number>
export type CurrencyCodeList = [ExchangeRateCode, string][]
