export const themeColors = [
    "accent",
    "primary-blue",
    "primary-green",
    "primary-orange",
    "sky",
    "night",
    "coral",
    "mint",
    "pine",
    "white",
    "fog",
    "shark",
    "cloud",
    "charcoal",
    "black",
    "dark",
] as const

export const colorValueMap = {
    "primary-blue": "#3C67F4",
    "primary-green": "#00CB39",
    "primary-orange": "#FF5B00",
    "error": "red",
    "primary-black": "#000",
    "primary-white": "#FEFFEE",
    "dark": "#181818",
    "black": "black",
    "white-alpha-7": "rgba(#FEFFEE, 0.7)",
    "fog": "#f2f2f2",
    "shark": "#c4c4c4",
    "cloud": "#808080",
    "charcoal": "#404040",
    "sky": "#64C4FF",
    "night": "#OA1E63",
    "coral": "#FE5A50",
    "mint": "#82E6B4",
    "pine": "#004137",
}
