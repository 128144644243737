
import s from "./Splash.module.scss"

interface SplashProps {
    children: React.ReactNode
}

export default function Splash(props: SplashProps)  {
    return (
        <div className={ s.splash }>
            { props.children }
        </div>
    )
}
