import { Instalment } from "src/modules/types"
import { LoanRates } from "src/lib/lendify/lendify.service"
import { RATE } from "./rate"



export const instalmentCalculator = (
    loanAmount: number,
    rateOfAnnualInterest: number,
    loanTerm: number,
    rateOfAnnualInterestRebate: number,
) => {
    const rateOfInterest = rateOfAnnualInterest/12/100
    const discountRate = rateOfAnnualInterestRebate/12/100
    const invoicingFee = 19

    /** EMI calculation */
    return Math.round(loanAmount * (rateOfInterest - discountRate) * (1 + (rateOfInterest - discountRate)) ** loanTerm / (((1 + (rateOfInterest - discountRate)) ** loanTerm) - 1)) + invoicingFee
}

export const effectiveRateCalculation = (
    emi: number,
    loanAmount: number,
    loanTerm: number,
) => {
    // var effectiveRate = (Math.Pow((1 + Microsoft.VisualBasic.Financial.Rate(months, -(emi + invoicingFee), baseAmount)), 12) - 1.0) * 100;
    const rateValue = RATE(loanTerm, -(emi), loanAmount, 0.0, 0, 0.1)

    return (Math.pow((1 + rateValue), 12) -1) * 100
}


export const instalmentsGenerator = (instalmentData: {
    minLoanPeriod: number
    maxLoanPeriod: number
    amountToLoan: number
    loanRates: LoanRates
    currency: string
    periodInformation: string
    originationFeeRebate?: number
    rateOfAnnualInterestRebate?: number

}) => {
    const { minLoanPeriod, maxLoanPeriod, amountToLoan, loanRates, currency, periodInformation, originationFeeRebate = 0, rateOfAnnualInterestRebate = 0 } = instalmentData
    const availabeInstalments: Instalment[] = []
    for (let i = minLoanPeriod; i <= maxLoanPeriod; i++) {
        const rateOfAnnualInterest = loanRates.rates[`year${i}`].rate
        const emi = instalmentCalculator(amountToLoan + (loanRates.originationFee - originationFeeRebate), rateOfAnnualInterest, i * 12, rateOfAnnualInterestRebate)

        const instalment: Instalment = {
            currency,
            instalmentAmount: emi,
            numberOfYears: i,
            periodInformation,
        }

        availabeInstalments.push(instalment)
    }

    return availabeInstalments
}
