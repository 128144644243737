import React from "react"
import { Section } from "src/utils"
import s from "./RichTextModule.module.scss"
import { Container } from "src/layout/container/Container"
import { SanityRichText } from "src/sanity/types"
import RichText from "src/components/rich-text/RichText"

interface RichTextModuleProps {
    body: SanityRichText
    removeMarginTop?: boolean
    removeMarginBottom?: boolean
}

export default function RichTextModule({ body }: RichTextModuleProps): JSX.Element {
    return (
        <Section>
            <Container>
                <div className={s["rich-text-module"]}>
                    <RichText blocks={body} className={s["paragraph-200"]} />
                </div>
            </Container>
        </Section>
    )
}
