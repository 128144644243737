import React from "react"
import Icon, { IconType } from "../icon/Icon"
import { CarouselItem, CarouselItemSkeleton } from "./CarouselItem"

import s from "./Carousel.module.scss"
import cn from "classnames"
import { Instalment } from "src/modules/types"

/** The full width from design with margin to handle translate */
const widthOfOneElement = 118

type CarouselProps = {
    elements: Instalment[]
    yearsText: string
    selectedInstalment: number
    setSelectedInstalment: (instalmentIndex: number) => void
    className?: string
}

export const Carousel = ({ elements, yearsText, selectedInstalment, setSelectedInstalment, className }: CarouselProps) => {
    const [valueOfTranslate, setValueOfTranslate] = React.useState(0)
    const [touchValue, setTouchValue] = React.useState(0)
    const carouselRef = React.useRef(null)

    React.useEffect(() => {
        if(elements.length > 0) {
            carouselRef.current.style.setProperty("--transformX", valueOfTranslate)
        }
    }, [carouselRef, valueOfTranslate, elements])

    React.useEffect(() => {
        if (selectedInstalment > -1) {
            let newValueOfTranslate = selectedInstalment * widthOfOneElement

            if (selectedInstalment > 12) {
                newValueOfTranslate = document.body.getBoundingClientRect().width > 768 ? selectedInstalment * widthOfOneElement - 4 * widthOfOneElement : selectedInstalment * widthOfOneElement - 2 * widthOfOneElement
                return setValueOfTranslate(-newValueOfTranslate)
            }
            if (selectedInstalment > 2) {
                const centeringValue = document.body.getBoundingClientRect().width > 768 ? 0 : widthOfOneElement
                newValueOfTranslate = selectedInstalment * widthOfOneElement - 2 * widthOfOneElement + centeringValue
                return setValueOfTranslate(-newValueOfTranslate)

            }
            return setValueOfTranslate(-newValueOfTranslate)
        }
    }, [selectedInstalment])


    const handleMove = (operation: "inc" | "dec") => {
        /**
         * Because of difference in displaying carousel, for narrower screen we have to add twice the width of the tile to max transform value
         * (because on mobile the start view consists of 3 tiles and desktop of 5 tiles)
         */
        const borderForTransform = document.body.getBoundingClientRect().width > 768 ? -(elements.length * widthOfOneElement - (elements.length / 3 * widthOfOneElement)) : -(elements.length * widthOfOneElement) + (3 * widthOfOneElement)
        if (operation === "inc" && valueOfTranslate > borderForTransform) {
            setValueOfTranslate(valueOfTranslate - widthOfOneElement)
        } else if (operation === "dec" && valueOfTranslate < 0) {
            setValueOfTranslate(valueOfTranslate + widthOfOneElement)
        }
    }

    const handleSwipe = (isRightSwipe: boolean) => {
        if (isRightSwipe) {
            handleMove("inc")
        } else {
            handleMove("dec")
        }
    }

    const touchStartHandler = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchobj = e.changedTouches[0]
        setTouchValue(touchobj.pageX)
    }


    const touchEndHandler = (e: React.TouchEvent<HTMLDivElement>) => {
        const touchobj = e.changedTouches[0]
        handleSwipe(touchobj.pageX - touchValue < 0)
        setTouchValue(0)
    }

    const translateHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, operation: "inc" | "dec") => {
        e.stopPropagation()
        handleMove(operation)
    }


    return <section className={cn(s["carousel"], className ? className : "")}>
        <div
            onClick={(e) => translateHandler(e, "dec")}
            className={s["carousel__arrow"]}
        >
            <Icon type={IconType.ARROW_LEFT} />
        </div>
        <div className={s["carousel__container"]}>
            <div
                ref={carouselRef}
                className={s["carousel__elements"]}
                onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => touchStartHandler(e)}
                onTouchEnd={(e: React.TouchEvent<HTMLDivElement>) => touchEndHandler(e)}
            >
                { elements.length !== 0 ?
                    elements.map((d, index) => <CarouselItem
                        onClick={() => setSelectedInstalment(index)}
                        selected={selectedInstalment === index}
                        key={d.numberOfYears}
                        yearInfo={yearsText}
                        {...d}
                    />) : [...Array(5)].map((v, idx) => <CarouselItemSkeleton key={ idx } />)
                }
            </div>
        </div>
        <div
            onClick={(e) => translateHandler(e, "inc")}
            className={s["carousel__arrow"]}
        >
            <Icon type={IconType.ARROW_RIGHT} />
        </div>
    </section>
}


