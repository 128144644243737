import { Heading, Only, Section } from "src/utils"
import React from "react"
import s from "./ProductListModule.module.scss"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import { Product, SanityCallToAction } from "src/sanity/types"
import ProductList from "src/components/product-list/ProductList"
import Card from "src/components/card/Card"
import CallToAction from "src/components/call-to-action/CallToAction"

interface ProductListModuleProps {
    title: string
    list: Product[]
    cta: SanityCallToAction
}

export default function ProductListModule({ title, list, cta }: ProductListModuleProps): JSX.Element {
    return (
        <Section>
            <Container>
                <Heading className={cn(s["product-list-module__title"], "heading-300")}>{title}</Heading>

                <ProductList centered>
                    {(list || []).map((product, index) => {
                        return (
                            <Card
                                key={product.id + index}
                                title={product.title}
                                // backgroundImage={product.backgroundImage}
                                badge={product.badge}
                                badgeTheme={product.badgeTheme}
                                text={product.shortDescription}
                                link={product.page}
                            />
                        )
                    })}
                </ProductList>

                <Only if={cta.style}>
                    <div className={s["product-list-module__cta"]}>
                        <CallToAction {...cta} />
                    </div>
                </Only>
            </Container>
        </Section>
    )
}
