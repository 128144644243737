import React, { useState } from "react"

interface AccordionProps {
    className?: string
    initialActive?: number
    onActivate?: (index: number) => void
    children?: React.ReactElement<{ id:string, index: number, activeTab: number, activateTab: (index: number) => void }>[]
}

export default function Accordion({ className, children, onActivate, initialActive = -1 }: AccordionProps): JSX.Element {
    const [activeTab, setActiveTab] = useState<number[]>([initialActive])
    const isMobile = typeof window !== "undefined" && window.innerWidth < 768

    const activateTab = (index: number) => {
        if (!isMobile) {
            setActiveTab(prev => prev.includes(index) ? [] : [index])
        } else {
            if (activeTab.includes(index))
                setActiveTab(prev => prev.filter(curr => curr !== index))
            else
                setActiveTab(prev => [...prev, index])
        }

        onActivate?.(index)
    }

    return (
        <ul role='tablist' className={className}>
            {React.Children.map(children, (child, index) => {
                return (
                    <li key={index}>
                        {React.cloneElement(child, {
                            activeTab: activeTab.includes(index) ? index : -1,
                            index: index,
                            activateTab: activateTab,
                        })}
                    </li>
                )
            })}
        </ul>
    )
}
