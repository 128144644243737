import React from "react"
import { SanityMedia } from "../../sanity/types"
import { generateSanityImage } from "../../sanity/image"
import s from "./FullscreenMedia.module.scss"
import { BasicImage } from "../image/BasicImage"
import type { Player, VimeoVideoQuality } from "@vimeo/player"
import dynamic from "next/dynamic"
const Vimeo = dynamic(() => import("@u-wave/react-vimeo"))

export default function FullscreenMedia({ media, portraitMedia }: { media: SanityMedia, portraitMedia?: SanityMedia }): JSX.Element {
    const aspectRatioWidth = media.width / media.height * 100 + "vh"
    const aspectRatioHeight = media.height / media.width * 100 + "vw"
    const [loaded, setLoaded] = React.useState<boolean>(false)

    const onReady = (player: Player & { setQuality: (quality: VimeoVideoQuality) => Promise<unknown> }) => {
        // This is a workaround until this is merged and released: https://github.com/u-wave/react-vimeo/pull/132
        if (player && player.setQuality) {
            player.setQuality("720p")
                .catch((error) => {
                    // eslint-disable-next-line no-console
                    console.error("Error changing quality", error)
                })
        }
        setLoaded(true)
    }

    if (media.type === "image") {

        if (portraitMedia && portraitMedia?.type === "image") {
            return (
                <picture
                    className={s["fullscreen-media__image"]}
                >
                    <source
                        media="(orientation: landscape)"
                        srcSet={generateSanityImage({
                            image: media,
                            step: 500,
                            width: media.width || 1440,
                            height: media.height || 820,
                        }).srcSet.join(", ")}
                        sizes="100vw"
                    />
                    <source
                        media="(orientation: portrait) and (max-width: 1000px)"
                        srcSet={generateSanityImage({
                            image: portraitMedia,
                            step: 500,
                            width: portraitMedia.width || 1440,
                            height: portraitMedia.height || 820,
                        }).srcSet.join(", ")}
                        sizes="100vw"
                    />
                    <BasicImage
                        sizes={["100vw"]}
                        alt={media?.originalFilename}
                        {...generateSanityImage({
                            image: portraitMedia,
                            step: 500,
                            width: portraitMedia.width || 1440,
                            height: portraitMedia.height || 820,
                        })}
                    />
                </picture>
            )
        }

        return (
            <BasicImage
                className={s["fullscreen-media__image"]}
                sizes={["100vw"]}
                {...generateSanityImage({
                    image: media,
                    step: 500,
                    width: media.width || 1440,
                    height: media.height || 820,
                })}
            />
        )
    }

    if (media.type === "video") {
        return (
            <div
                className={s["fullscreen-media__video"]}
                style={{
                    backgroundImage: `url(${media.thumbnail.url})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}>
                <Vimeo
                    className={s["fullscreen-media__video"]}
                    style={{ visibility: loaded ? "visible" : "hidden", "--aspect-ratio-width": aspectRatioWidth, "--aspect-ratio-height": aspectRatioHeight } as React.CSSProperties}
                    video={media.src}
                    autoplay={media.autoplay}
                    muted={media.autoplay}
                    loop={media.loop}
                    dnt={true}
                    width={media.width}
                    height={"auto"}
                    controls={media.controls}
                    onReady={onReady}
                />
            </div>
        )
    }

    return null
}
