import React, { useEffect, useRef, useState } from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import { SanityMedia, SanityRichText } from "src/sanity/types"
import s from "./FeatureWalkthroughModule.module.scss"
import { Section, Heading } from "src/utils"
import FeatureWalkthroughItem from "./FeatureWalkthroughItem"
import FeatureWalkthroughProgressBar from "./FeatureWalkthroughProgressBar"
import Icon, { IconType } from "src/components/icon/Icon"
import RichText from "src/components/rich-text/RichText"
import { useSettings } from "../../layout/page/settingsContext"
import { generateSanityImage } from "src/sanity/image"

export const FALLBACK_DURATION = 3500

export interface Feature {
    body: SanityRichText
    media: SanityMedia
    id: string
    duration: number
}

export interface FeatureWalkthroughModuleProps {
    title: string
    list: Feature[]
}

export default function FeatureWalkthroughModule({
    title,
    list,
}: FeatureWalkthroughModuleProps): JSX.Element {
    const [activeTab, setActiveTab] = useState<number>(0)
    const [visible, setVisible] = useState<boolean>(false)
    const [hasInteracted, setHasInteracted] = useState<boolean>(false)
    const tab = list[activeTab]
    const ref = useRef()
    const { translations: t } = useSettings()

    useEffect(() => {
        if (!ref.current) {
            return
        }

        const observer = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                setVisible(true)
                observer.disconnect()
            }
        }, { threshold: .65 })

        observer.observe(ref.current)

        return () => {
            observer.disconnect()
        }
    }, [])

    useEffect(() => {
        if (visible) {
            const id = setTimeout(() => {
                setActiveTab(prev => prev + 1 <= list.length - 1 ? prev + 1 : 0)
            }, tab.duration || FALLBACK_DURATION)

            return () => {
                clearTimeout(id)
            }
        }
    }, [tab, visible, list])

    useEffect(() => {
        const image = new Image()

        for (const item of list) {
            if (item.media.type === "image") {
                const { srcSet, src } = generateSanityImage({ image: item.media, width: 292, height: 620 })
                image.sizes =  "292px"
                image.srcset =  srcSet.join(", ")
                image.src = src
            }
        }
    }, [list])

    return (
        <Section>
            <div ref={ref} className={cn(s["feature-walkthrough-module"])}>
                <Container
                    className={cn(s["feature-walkthrough-module__wrapper"])}
                >
                    <Heading
                        className={cn(
                            s["feature-walkthrough-module__title"],
                            "heading-200",
                        )}
                    >
                        {title}
                    </Heading>
                    <div className={s["feature-walkthrough-module__image"]}>
                        {list.map((i, index) => {
                            return (
                                <FeatureWalkthroughItem
                                    key={i.id}
                                    activeTab={activeTab}
                                    index={index}
                                    {...i}
                                />
                            )
                        })}
                    </div>
                    <div className={s["feature-walkthrough-module__content"]} >
                        {list.map((i, index) => {
                            const isActive = activeTab === index

                            return (
                                <div
                                    key={i.id}
                                    className={cn(
                                        s["feature-walkthrough-module__text"],
                                        {
                                            [s["feature-walkthrough-module__text--active"]]: isActive,
                                        },
                                    )}
                                >
                                    <RichText
                                        blocks={i.body}
                                        className="paragraph-100"
                                    />
                                </div>
                            )
                        })}

                        <div
                            className={s["feature-walkthrough-module__tab-bar"]}
                        >
                            {list.map((i, index) => {
                                return (
                                    <FeatureWalkthroughProgressBar
                                        key={i.id}
                                        activeTab={activeTab}
                                        index={index}
                                        visible={visible || hasInteracted}
                                        duration={i.duration || FALLBACK_DURATION}
                                        setActive={() => setActiveTab(index)}
                                    />
                                )
                            })}
                        </div>
                        <div
                            className={s["feature-walkthrough-module__arrows"]}
                        >
                            <button
                                className={cn(s["feature-walkthrough-module__arrow"], {
                                    [s["feature-walkthrough-module__off"]]: activeTab === 0,
                                })}
                                onClick={() => {
                                    if (activeTab === 0) return
                                    setHasInteracted(true)
                                    setActiveTab(activeTab - 1)
                                }}
                            >
                                <Icon
                                    fill={true}
                                    type={IconType.ARROW_CIRCLE_LEFT}
                                />
                                <span className="visually-hidden">{t?.featureWalkthrough?.previous}</span>
                            </button>

                            <button
                                className={cn(s["feature-walkthrough-module__arrow"], {
                                    [s["feature-walkthrough-module__off"]]: activeTab === list.length - 1,
                                })}
                                onClick={() => {
                                    if (activeTab === list.length - 1) return
                                    setHasInteracted(true)
                                    setActiveTab(activeTab + 1)
                                }}
                            >
                                <Icon
                                    fill={true}
                                    type={IconType.ARROW_CIRCLE_RIGHT}
                                />
                                <span className="visually-hidden">{t?.featureWalkthrough?.next}</span>
                            </button>
                        </div>
                    </div>
                </Container>
            </div>
        </Section>
    )
}
