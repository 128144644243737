import React from "react"
import RichText from "../rich-text/RichText"
import s from "./RepresentativeExample.module.scss"


type RepresentativeExampleProps = {
    representativeExampleContent?: any
}

export const RepresentativeExample = ({ representativeExampleContent }: RepresentativeExampleProps) => {
    return <section className={s["representative-example"]}>
        <RichText blocks={representativeExampleContent} />
    </section>
}
