import { CollageModuleProps } from "src/modules/collage-module/CollageModule"
import { ScreenshotLargeTextModuleProps } from "src/modules/collage-screenshot-large-module/CollageScreenshotLargeModule"
import { HeroBasicModuleProps } from "src/modules/hero-basic-module/HeroBasicModule"
import { stringToRichText, richTextToString } from "src/utils"
import { ModuleType } from "src/sanity/types"
import { CollageDefaultModuleProps } from "../../modules/collage-default-module/CollageDefaultModule"
import { CollageScreenshotModuleProps } from "../../modules/collage-screenshot-module/CollageScreenshotModule"
import { HeroCollageModuleProps } from "../../modules/hero-collage-module/HeroCollageModule"
import { HeroFullImageModuleProps } from "../../modules/hero-full-image-module/HeroFullImageModule"

export function rewriteCollageScreenshot({
    shapePosition, // eslint-disable-line @typescript-eslint/no-unused-vars
    screenshotPosition, // eslint-disable-line @typescript-eslint/no-unused-vars
    midMediaPosition,
    midMedia,
    screenshot,
    ...props
}: CollageScreenshotModuleProps): CollageDefaultModuleProps {
    return {
        shapeAngle: "top",
        mediaPosition: midMediaPosition,
        media: midMedia,
        uiMedia: screenshot,
        mediaShape: {
            bottomLeftRadius: "edge",
            bottomRightRadius: "edge",
            topLeftRadius: "edge",
            topRightRadius: "edge",
        },
        ...props,
    }
}

export function rewriteToCollageModule(props, moduleType: ModuleType): CollageModuleProps {
    if (moduleType === ModuleType.HERO_COLLAGE) {
        const p: HeroCollageModuleProps = props
        return {
            box1: {
                title: {
                    text: stringToRichText(uppercaseFirstWord(p.largeTitle)),
                    size: "heading-300",
                    lightFontWeight: true,
                },
                subtitle: {
                    text: uppercaseFirstWord(p.smallTitle),
                },
                cta: p.cta,
            },
            box2: {
                media: {
                    media: p.media,
                    position: "center",
                },
                boxSettings: {
                    backgroundColor: mapPre2023ColorsTo2023(p.shapeColor),
                },
            },
            boxFormat: "medium",
        }
    }
    if (moduleType === ModuleType.COLLAGE_DEFAULT) {
        const p: CollageDefaultModuleProps = props
        const setup: CollageModuleProps = {
            box1: {
                title: {
                    text: stringToRichText(uppercaseFirstWord(p.title)),
                    size: p.titleSize || "heading-100",
                },
                bodyText: {
                    text: p.body,
                },
                cta: p.cta,
                secondaryCta: p.secondaryCta,
            },
            box2: {
                media: {
                    media: p.media,
                    position: p.uiMedia?.type ? "background-fill" : "center",
                },
                overlayMedia: {
                    media: p.uiMedia,
                    position: "center",
                },
                boxSettings: {
                    backgroundColor: p.hideShape ? null : mapPre2023ColorsTo2023(p.shapeColor),
                    narrow: true,
                    verticallyCenter: true,
                },
            },
            boxFormat: "medium",
            swapBoxOrder: p.textPosition === "right",
        }

        return setup
    }
    if (moduleType === ModuleType.HERO_FULL_IMAGE) {
        const p: HeroFullImageModuleProps = props
        return {
            box1: {
                title: {
                    text: stringToRichText(uppercaseFirstWord(richTextToString(p.largeTitle))),
                    size: p.titleSize === "large" ? "heading-400" : "heading-300",
                    lightFontWeight: true,
                },
                ...(!p.subtitle ? {} : { bodyText: {
                    text: stringToRichText(p.subtitle),
                } }),
                subtitle: {
                    text: uppercaseFirstWord(p.smallTitle),
                },
                cta: p.cta,
                secondaryCta: p.secondaryCta,
            },
            box2: {
                media: {
                    media: { ...p.media, ...(p.media.type === "image" ? { hotspot: { _type: "sanity.imageHotspot", x: 0.75, y: 0.5, height: 1, width: 0.6 } } : {}) },
                    position: "background-fill",
                },
            },
            boxFormat: "square",
        }
    }
    if (moduleType === ModuleType.COLLAGE_SCREENSHOT_LARGE) {
        const p: ScreenshotLargeTextModuleProps = props
        const setup: CollageModuleProps = {
            box1: {
                title: {
                    text: stringToRichText(uppercaseFirstWord(p.title)),
                    size: "heading-100",
                },
                bodyText: {
                    text: p.body,
                },
                cta: p.cta,
            },
            box2: {
                media: {
                    media: { ...p.media },
                    position: "bottom",
                },
                boxSettings: {
                    backgroundColor: mapPre2023ColorsTo2023(p.shapeColor),
                },
            },
            boxFormat: "medium",
            swapBoxOrder: p.textPosition === "right",
        }
        return setup
    }
    if (moduleType === ModuleType.HERO_BASIC) {
        const p: HeroBasicModuleProps = props
        const setup: CollageModuleProps = {
            box1: {
                title: {
                    text: stringToRichText(uppercaseFirstWord(p.title)),
                    size: "heading-100",
                },
                cta: p.cta,
            },
            box2: {
            },
            boxFormat: "medium",
            backgroundColor: p.backgroundColor,
        }
        return setup
    }
    return props
}

function mapPre2023ColorsTo2023(color: Sanity.Schema.Colors) {
    const map: Partial<Record<Sanity.Schema.Colors, Sanity.Schema.Colors>> = {
        "black": "primary-blue",
        "dark": "primary-blue",
        "sky": "primary-blue",
        "night": "primary-blue",
        "coral": "primary-blue",
        "mint": "primary-green",
        "pine": "primary-green",
    }
    return map[color] || color
}

function uppercaseFirstWord(str: string) {
    if (!str) return str
    const isAllUppercase = str === str.toUpperCase()
    if (isAllUppercase) {
        return str.slice(0,1) + str.slice(1).toLowerCase()
    }
    return str
}
