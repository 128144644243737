import { Heading } from "src/utils"
import React, { useRef } from "react"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import type { HeroStyle, Subscription } from "src/sanity/types"
import { generateSanityImage } from "src/sanity/image"
import { BasicImage } from "src/components/image/BasicImage"
import SubscriptionsList from "src/components/subscriptions-list/SubscriptionsList"
import s from "./HeroSubscriptionsModule.module.scss"
import { DefaultHeroMediaProps, DefaultHeroProps } from "../hero-base/types"

interface HeroSubscriptionsModuleProps extends DefaultHeroProps, DefaultHeroMediaProps {
    subscriptions: Omit<Subscription, "benefits">[]
    heroStyle: HeroStyle
}

export default function HeroSubscriptionsModule({
    title,
    media,
    subscriptions = [],
    heroStyle,
}: HeroSubscriptionsModuleProps): JSX.Element {
    const heroRef = useRef<HTMLDivElement>()

    return (
        <div
            ref={heroRef}
            className={cn(s["hero-subscriptions-module"], s["hero-subscriptions-module--" + heroStyle])}
        >

            <div className={s["hero-subscriptions-module__hero"]}>
                <Container className={s["hero-subscriptions-module__title-wrapper"]}>
                    <Heading className={cn(s["hero-subscriptions-module__title"], "heading-400")}>{title}</Heading>
                </Container>

                {media?.type === "image" && <BasicImage
                    className={s["hero-subscriptions-module__image"]}
                    {...generateSanityImage({
                        image: media,
                        width: 1920,
                        height: 1080,
                    })}
                    sizes={["100vw"]}
                    alt=""
                />}

            </div>

            <div className={s["hero-subscriptions-module__subscriptions"]}>
                <Container>
                    <SubscriptionsList subscriptions={subscriptions} />
                </Container>
            </div>
        </div>
    )
}
