import React, { useEffect, useState } from "react"
import { Heading, Section } from "src/utils"
import { Container } from "src/layout/container/Container"
import s from "./CurrencyTableModule.module.scss"
import fetchy from "src/api/fetch"
import cn from "classnames"
import { getTranslation } from "src/lib/translations"
import { useSettings } from "src/layout/page/settingsContext"
import { CurrencyCodeList, ExchangeRateMap } from "src/sanity/types"
import flags from "../../../assets/data/flags.json"

interface CurrencyTableModuleProps {
    title: string
    onlyPopular: boolean
}

export default function CurrencyTableModule({ title, onlyPopular = false }: CurrencyTableModuleProps): JSX.Element {
    const [exchangeMap, setExchangeMap] = useState<ExchangeRateMap>({})
    const [codesMap, setCodesMap] = useState<CurrencyCodeList>([])
    const { lang } = useSettings()
    const code = {
        dk: "DKK",
        no: "NOK",
        en: "DKK",
        se: "SEK",
    }[lang]
    const actualLang = {
        dk: "da",
    }[lang] || lang
    const t = getTranslation("currencyTable", lang)

    useEffect(() => {
        const getExchangeMap = async () => {
            let [exchanges, codes] = await Promise.all([
                fetchy<ExchangeRateMap>(`/api/exchange-rate?from=${code}`),
                fetchy<CurrencyCodeList>("/api/exchange-codes"),
            ])

            if (onlyPopular) {
                const popularCurrencies = ["DKK", "SEK", "NOK", "EUR", "USD", "GBP"]
                exchanges = Object.entries(exchanges)
                    .filter(([code]) => popularCurrencies.includes(code))
                    .reduce((obj, [code, value]) => {
                        return {
                            ...obj,
                            [code]: value,
                        }
                    }, {})

                codes = codes.filter(([code]) => popularCurrencies.includes(code))
            }
            console.log({ onlyPopular, codes })

            setExchangeMap(exchanges)
            setCodesMap(codes)
        }

        getExchangeMap()
    }, [code])

    return (
        <Section>
            <Container>
                <div className={cn(s["currency-table-module"])}>
                    <Heading className={cn(s["currency-table-module__title"], "heading-100 light-font-weight")}>
                        {title}
                    </Heading>

                    <table className={s["currency-table-module__table"]}>
                        <thead>
                            <tr>
                                <th>{t.code}</th>
                                <th>{t.name}</th>
                                <th>{t.value}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.entries(exchangeMap).map(([code, value]) => {
                                const name = codesMap.find(i => i[0] === code)[1]

                                return (
                                    <tr key={code}>
                                        <td>{code}</td>
                                        <td>{flags[code]} {name}</td>
                                        <td>{(value * 100).toLocaleString(actualLang, { maximumFractionDigits: 2 })} {code}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </Container>
        </Section>
    )
}
