import React from "react"
import { Heading, Only, Section } from "src/utils"
import { SanityMedia } from "../../sanity/types"
import { Container } from "src/layout/container/Container"
import cn from "classnames"
import FullscreenMedia from "src/components/fullscreen-media/FullscreenMedia"
import s from "./FullscreenImageModule.module.scss"

interface FullscreenImageModuleProps {
    title?: string
    titlePosition: "top" | "bottom"
    media?: SanityMedia
    portraitMedia?: SanityMedia
    className?: string
}

export default function FullscreenImageModule({ title, titlePosition, media, portraitMedia, className }: FullscreenImageModuleProps): JSX.Element {
    return (
        <Section>
            <figure
                className={cn(s["full-screen-image-module"], className)}
            >
                <FullscreenMedia media={media} portraitMedia={portraitMedia}/>

                <Only if={title}>
                    <figcaption
                        className={cn(s["full-screen-image-module__content"], {
                            [s["full-screen-image-module__content--title-bottom"]]: titlePosition === "bottom",
                        })}
                    >
                        <Container className={cn(s["full-screen-image-module__content-inner"])}>
                            <Heading className={cn(s["full-screen-image-module__title"], "heading-500")}>
                                {title}
                            </Heading>
                        </Container>
                    </figcaption>
                </Only>
            </figure>
        </Section>
    )
}
