export const numberWithThousandSeparator = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
}

export const numberWithDecimal = (x: number, locale = "en-EN"): string => {
    return parseFloat(x.toFixed(2))
        .toLocaleString(
            locale,
            {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
                useGrouping: true,
            },
        )
        .replace(".", ",")
}
